.json-container {
    overflow-y: auto;
    padding: 16px;
}

.modal-btns-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
}

.modal-btns-container .edit-btn {
    margin-left: 12px;
}
  