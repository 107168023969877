.journal_events_table > div {
  overflow-x: unset;
}

.journal_events_table thead {    
  z-index: 999;
  position: sticky;
  position: -webkit-sticky;
  top: 86px;
}

.journal_events_table thead th {    
  background-color: #fff;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  padding-left: 12px;
  padding-right: 12px;
  min-width: auto;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.journal_events_table tbody td {    
  padding-left: 12px;
  padding-right: 12px;
}