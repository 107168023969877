.pagination {
    list-style: none;
    display: flex;
    margin: 16px 24px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 0.875rem;
    color: #7B809A;
    overflow-x: scroll;
    padding-bottom: 8px;
    max-width: 400px;
}

.li_styles, .previous, .next, .break-me {
    position: relative;
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 1px solid #dee2e6;
    margin: 3px;
    cursor: pointer;
}

.li_styles a, .previous a, .next a, .break-me a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: 100%;
    border-radius: 100%;
}

.li_styles:hover, .previous:hover, .next:hover {
    transition: .3s;
    background-color: #eff2f5;
}

.pagination .active {
    transition: .3s;
    background-color: #348ced !important;
    color:white !important;
    border: none !important;
    box-shadow: 
        0rem 0.1875rem 0.1875rem 0rem rgb(26 115 232 / 15%), 
        0rem 0.1875rem 0.0625rem -0.125rem rgb(26 115 232 / 20%), 
        0rem 0.0625rem 0.3125rem 0rem rgb(26 115 232 / 15%);
}

.hide {
    display: none;
}

.pagination_container {
    display: flex;
    justify-content: flex-end;
}

.pagination::-webkit-scrollbar {
    height: 3px;
}
    
.pagination::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: lightgray;
    width: 100px;
}
