.search_panel {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
}

.search_panel .search_button {
    margin-left: 8px;
    font-weight: normal;
}

.search_panel .count_value {
  position: absolute;
  left: 0;
  padding: 9px 24px;
  font-weight: 400;
  font-size: 14px;
  color: rgb(123, 128, 154);
}

.search_panel .async_autocomplete {
    min-width: 300px;
}

.search_panel .autocomplete{
    min-width: 300px;
}

.p-0 {
    padding: 0;
}

table th {
    min-width: 100px;
}

.dropdown_container {
    display: flex;
}

.dropdown_container .search_button{
    margin-left: 24px;
    font-weight: normal;
}

table td > div {
    width: auto !important;
}
